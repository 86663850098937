<template>
  <div
    class="
      w-100
      d-flex
      gap-2
      border
      rounded-2
      justify-content-between
      align-items-between
    "
  >
    <div class="px-2 selected-name d-flex gap-2 align-items-centers">
      <div v-if="loading" class="d-flex gap-2 align-items-center">
        <div>
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </div>
        <div>
          <span>Uploading</span>
        </div>
      </div>
      <div v-else>
        <span class="text-nowrap" v-if="!modelValue && !disabled"
          >Pilih File</span
        >
        <span class="text-nowrap" v-else-if="!modelValue && disabled"
          >Tidak Ada File</span
        >
        <div class="d-flex gap-2 align-items-center" v-else>
          <div v-if="!disabled" class="btn-clear" @click="handleClear()">
            <inline-svg :src="`media/gws/cross.svg`" />
          </div>
          <a :href="modelValue" target="_blank">Lihat File</a>
        </div>
      </div>
    </div>
    <div
      v-if="!disabled"
      class="bg-light px-2 cursor-pointer button-up"
      @click="$refs.file.click()"
    >
      <i class="fa fa-upload"></i>
    </div>
    <input
      ref="file"
      type="file"
      hidden
      @change="handleUploadFileNew"
      :accept="accept"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "FileUpload",
  setup(props, { emit }) {
    const fileName = ref("");
    const loading = ref<boolean>(false);
    const handleClear = () => {
      emit("update:modelValue", null);
    };
    const handleUploadFileNew = async (e) => {
      loading.value = true;
      const file = e.target.files[0];
      const newName = `${uuidv4()}${props.prefix !== "" ? "_" : ""}${
        props.prefix
      }.${file.type.split("/")[1]}`;
      const uploadDataPayload = new FormData();
      uploadDataPayload.append("file", file);
      uploadDataPayload.append("name", newName);
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postUpload(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/assets`,
            uploadDataPayload as AxiosRequestConfig
          );
        if (response.status === 201) {
          emit("update:modelValue", response.data.data.url);
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };
    return {
      handleUploadFileNew,
      loading,
      handleClear,
      fileName,
    };
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-name {
  max-width: 100%;
  overflow-x: auto;
}
.btn-clear {
  cursor: pointer;
}
</style>
