import ApiService from "@/core/services/ApiService";
import { CpmiData } from "@/core/types/gws-agent/cpmi-detail/CpmiData";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useCpmiDetailFormStore = defineStore({
  id: "cpmiDetailFormStore",
  state: () => {
    return {
      stateLoading: false,
      stateCpmiData: {} as CpmiData,
    };
  },
  getters: {
    cpmiData(state) {
      return state.stateCpmiData;
    },
  },
  actions: {
    async getCpmiDetail(cpmiId: string) {
      this.stateLoading = true;
      this.stateCpmiData = {} as CpmiData;
      this.stateLoading = true;
      try {
        const response: AxiosResponse<CpmiData> = await ApiService.get(
          `${process.env.VUE_APP_GWS_API_BASE_URL}`,
          `cpmi/${cpmiId}`
        );
        const detail = response.data;
        this.stateCpmiData = detail;
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
  },
});
