
import { defineComponent, ref } from "vue";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "FileUpload",
  setup(props, { emit }) {
    const fileName = ref("");
    const loading = ref<boolean>(false);
    const handleClear = () => {
      emit("update:modelValue", null);
    };
    const handleUploadFileNew = async (e) => {
      loading.value = true;
      const file = e.target.files[0];
      const newName = `${uuidv4()}${props.prefix !== "" ? "_" : ""}${
        props.prefix
      }.${file.type.split("/")[1]}`;
      const uploadDataPayload = new FormData();
      uploadDataPayload.append("file", file);
      uploadDataPayload.append("name", newName);
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.postUpload(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/assets`,
            uploadDataPayload as AxiosRequestConfig
          );
        if (response.status === 201) {
          emit("update:modelValue", response.data.data.url);
        }
      } catch (error) {
        console.log(error);
      }
      loading.value = false;
    };
    return {
      handleUploadFileNew,
      loading,
      handleClear,
      fileName,
    };
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
  },
});
