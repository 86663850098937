import ApiService from "@/core/services/ApiService";
import { FormData } from "@/core/types/gws-agent/cpmi/FormData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import {
  formatDateTimeCustom,
} from "@/core/helpers/date-format";

export const useCpmiFormStore = defineStore({
  id: "cpmiFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateErrorMsg: "",
      stateUpdateId: "",
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    formData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
    errors(state) {
      return state.stateErrors;
    },
    errorMessage(state) {
      return state.stateErrorMsg;
    },
  },
  actions: {
    async insertData() {
      this.stateIsLoading = true;
      try {
        const payload = {
          agent_id: this.stateFormData.agent_id,
          company_id: this.stateFormData.company_id,
          ref_company_id: this.stateFormData.ref_company_id,
          syarikah_id: this.stateFormData.syarikah_id,
          first_name: this.stateFormData.first_name,
          middle_name: this.stateFormData.middle_name,
          last_name: this.stateFormData.last_name,
          province_id: this.stateFormData.province_id,
          city_id: this.stateFormData.city_id,
          job_fair_id: this.stateFormData.job_fair_id,
          nik: this.stateFormData.nik,
          birth_place: this.stateFormData.birth_place,
          birth_date: formatDateTimeCustom(this.stateFormData.birth_date,'YYYY-MM-DD'),
          age: this.stateFormData.age ?? 0,
          gender: this.stateFormData.gender,
          phone: this.stateFormData.phone,
          email: this.stateFormData.email,
          address: this.stateFormData.address,
          native_country: this.stateFormData.native_country,
          blood_type: this.stateFormData.blood_type,
          religion: this.stateFormData.religion,
          birth_certificate_file: this.stateFormData.birth_certificate_file,
          marital_status: this.stateFormData.marital_status,
          passport_number: this.stateFormData.passport_number,
          passport_request_date: this.stateFormData.passport_request_date,
          passport_expiry_date: this.stateFormData.passport_expiry_date,
          ktp_file: this.stateFormData.ktp_file,
          self_photo_file: this.stateFormData.self_photo_file,
          sik_file: this.stateFormData.sik_file,
          kk_file: this.stateFormData.kk_file,
          passport_file: this.stateFormData.passport_file,
          diploma_file: this.stateFormData.diploma_file,
          skck_file: this.stateFormData.skck_file,
          experience: this.stateFormData.experience,
          country: this.stateFormData.country,
          height: this.stateFormData.height ?? 0,
          weight: this.stateFormData.weight ?? 0,
          old_passport_file: this.stateFormData.old_passport_file,
          bpjs_file: this.stateFormData.bpjs_file,
          siap_kerja_file: this.stateFormData.siap_kerja_file,
        };
        console.log(payload);
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async updateData() {
      this.stateIsLoading = true;
      try {
        const payload = this.stateFormData;
        payload.birth_date = formatDateTimeCustom(this.stateFormData.birth_date, 'YYYY-MM-DD');
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.patch(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateUpdateId}`,
            payload as AxiosRequestConfig
          );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async deleteData(id: string) {
      this.stateIsLoading = true;
      try {
        const response: AxiosResponse<ApiResponse<any>> =
          await ApiService.delete(
            `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${id}`
          );
        if (response.status === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        this.stateErrors.push(error.toString() || "");
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    setFormData(data: FormData) {
      this.stateFormData = data;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
    },
  },
});
