
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    errorMessages: {
      type: Array,
      default: () => {
        return [];
      },
    },
    messageTitle: {
      type: String,
      default: "Error in Input Field",
    },
  },
  emits: ["resetForm"],
  setup(props, { emit }) {
    // computeds start
    const alertTitle = computed(() => {
      return props.messageTitle;
    });
    const alertMessages = computed(() => {
      return props.errorMessages;
    });

    // methods start
    const handleClose = () => {
      emit("resetForm");
    };
    return { alertTitle, alertMessages, handleClose };
  },
});
