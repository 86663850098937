import { defineStore } from "pinia";
import { ServiceFormData as FormData } from "@/core/types/gws-blk/ServiceFormData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";

const authStore = useAuthenticationStore();
export const useBlkServiceFormStore = defineStore({
  id: "blkServiceFormStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFormData: {} as FormData,
      stateError: false,
      stateErrors: [] as string[],
      stateUpdateId: "",
      stateUpdateBlk: "",
    };
  },
  getters: {
    loading: (state) => {
      return state.stateIsLoading;
    },
    formData: (state) => {
      return state.stateFormData;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async approveService(isPageRefresh = true) {
      this.stateIsLoading = true;
      try {
        const payload = {
          services_groups_blk_id: this.stateFormData.services_groups_blk_id,
          reject_reason: "",
          is_rejected: false,
          training_date: this.stateFormData.training_date,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/cpmi/${this.stateUpdateId}/services-blk/${this.stateUpdateBlk}/approval`,
          payload as AxiosRequestConfig
        );
        if (response.data.meta.code === 200) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },

    setFormData(data: FormData) {
      this.stateFormData = {} as FormData;
    },
    setErrors(errors: string[]) {
      this.stateErrors = errors;
      this.stateError = this.stateErrors.length > 0;
    },
    resetError() {
      this.stateError = false;
      this.setErrors([] as string[]);
    },
  },
});
